// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
global.$ = require("jquery")
require("jquery-ui")
// require("webpack-jquery-ui")
// require("webpack-jquery-ui/sortable")
// require("jquery.ui.touch-punch")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//$(document).on("turbolinks:load", () => {
//    $("#lists").sortable({
//
//        update: function(e, ui) {
//            Rails.ajax({
//                url: $(this).data("url"),
//                type: "PATCH",
//                data: $(this).sortable('serialize'),
//            });
//        }
//    });
//})

global.toastr = require("toastr")

toastr.options = {
    "closeButton": true,
    "positionClass": "toast-bottom-right"
};

global.Rails = Rails

//import "../stylesheets/application.scss"
require("stylesheets/application.scss")
require("trix")
require("@rails/actiontext")
require("chartkick/highcharts")